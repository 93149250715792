import React, { useState }  from 'react';
import './Navbar.scss';
import { images } from '../../constants';
import { RiMenu4Line, RiCloseFill } from "react-icons/ri";
import { motion } from 'framer-motion';



const Navbar = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <div className='navbar'>
      <nav>
        <div className="navbar-logo">
          <img src={images.logo} alt="brand logo" />
        </div>
        <ul className="navbar-links" >
        {['home', 'about', 'work', 'contact'].map((item) =>
          <li key={`link-${item}`}>
            <a href={`#${item}`}>{item}</a>
            <div />
          </li>
        )}
      </ul>
      <div className="app__navbar-menu">
        <RiMenu4Line onClick={ () => setToggle(true)} />
        {
          toggle && (
            <motion.div
              whileInView={{ x: [300, 0] }}
              transition={{ duration: 0.85, ease: 'easeOut' }}
            >
              <RiCloseFill onClick={ () => setToggle(false)} />
              <ul>
                {['home', 'about',  'work', 'contact'].map((item) =>
                  <li key={item}>
                    <a href={`#${item}`} onClick={ () => setToggle(false)}>{item}</a>
                  </li>
                )}
              </ul>
            </motion.div>
          )
        }
      </div>
      </nav>
    </div>
  )
}

export default Navbar