import React from 'react';
import { ScrollContainer, ScrollPage, Animator, MoveOut } from 'react-scroll-motion';
import './Header.scss';
import { motion } from 'framer-motion';
import { HiOutlineChevronDoubleDown } from 'react-icons/hi';
import { Link } from 'react-scroll';

const Header = () => {
  return (
    <ScrollContainer>
      <ScrollPage page={0}>
        <div name="home" className="header" id ="home">
          <div className="names">
            <Animator animation={MoveOut(-1000, 0)}>
              <motion.h1 
              className='names-fname' 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{delay: 0.3}}>Khensi</motion.h1>
            </Animator>
            <Animator animation={MoveOut(1000, 0)}>
              <motion.h1 
              className="names-mname" 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{delay: 0.3}}
              >Malaeka</motion.h1>
            </Animator>
          </div>
          <div className="job__title">
            <div className="job__title-line"></div>
            <h6 className="job__title-text">Frontend <br /> Web Developer</h6>
          </div>
          <motion.div 
            className="scroll__btn"
            whileHover={{
              scale: 1.1,
              y: 0,
              
              
            }}
              animate={{
                y: [-5,0,-5],
                
              }}
              transition={{
                yoyo: Infinity,
                type: "keyframe"
              }}
            >
        <Link to="about" spy={true} smooth={true} offset={-50} duration={100}><HiOutlineChevronDoubleDown></HiOutlineChevronDoubleDown></Link>
      </motion.div>
        </div>
      </ScrollPage>
    </ScrollContainer>
  )
}

export default Header