import React, { useState, useEffect } from 'react';
import { AiFillEye, AiFillGithub } from 'react-icons/ai';
import { motion } from 'framer-motion';
import { urlFor, client } from '../../client';
import './Work.scss';

const Work = () => {

  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });
  const [activeFilter, setActiveFilter] = useState('All');
  const [works, setWorks] = useState([]);
  const [filterWork, setFilterWork] = useState([]);
  

  useEffect(() => {
    const query = '*[_type == "works"]';
    client.fetch(query).then((data) => {
      setWorks(data);
      setFilterWork(data);
    })
  }, [])

  const handleWorkFilter = (item) =>{
    setActiveFilter(item);
    setAnimateCard([{ y: 100, opacity: 0 }]);

    setTimeout(() => {
      setAnimateCard([{ y: 0, opacity: 1 }]);

      if (item === 'All') {
        setFilterWork(works);
      } else {
        setFilterWork(works.filter((work) => work.tags.includes(item)));
      }
    }, 500);
  };

  return (
    
      <div name="work" className="section work" id="work">
        <h3 className="section-subtitle">Projects</h3>
        <h4 className="section-title">Recent Work</h4>

         
          <div className="projects-filter">
            {['All', 'HTML', 'Python', 'React', 'Angular', 'Mobile App' ].map((item, index) => (
              <div
                key={index}
                onClick={() => handleWorkFilter(item)}
                className={`projects-filter-item ${activeFilter === item ? 'item-active' : ''}`}
              >
                {item}
              </div>
            ))}
          </div>
        <motion.div 
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="projects"> 
              {filterWork.map((work, index) => (
                <div className='project' key={index}>
                  <div className="project-img">
                    <img src={urlFor(work.imgUrl)} alt={work.name}/>
                    <motion.div
                      whileHover={{ opacity: [0, 1] }}
                      transition={{ duration: 0.25, ease: 'easeInOut', staggerChildren: 0.5 }}
                      className="project-hover"
                    >
                    <a href={work.projectLink} target="_blank" rel="noreferrer">

                      <motion.div
                        whileInView={{ scale: [0, 1] }}
                        whileHover={{ scale: [1, 0.90] }}
                        transition={{ duration: 0.25 }}
                      >
                        <AiFillEye />
                      </motion.div>
                    </a>
                    <a href={work.codeLink} target="_blank" rel="noreferrer">
                      <motion.div
                        whileInView={{ scale: [0, 1] }}
                        whileHover={{ scale: [1, 0.90] }}
                        transition={{ duration: 0.25 }}
                      >
                        <AiFillGithub />
                      </motion.div>
                    </a>
                    </motion.div>
                  </div>
                  <div className="project-content">
                    <h4 className="project-title">{work.title}</h4>
                    <p className="project-desc">{work.description}</p>
                    
                  </div>
                  <div className="project-tags">
                    <p>{work.tags[0]}</p>
                    <p>{work.tags[1]}</p>
                    <p>{work.tags[2]}</p>
                  </div>
                  
                </div>
              ))}
        </motion.div>
      </div>

  
    
  )
}

export default Work