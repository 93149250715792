import React from 'react';
import './Contact.scss';

const Contact = () => {

  return (
    <div   name="contact" className='section contact' id="contact">
      <h3 className="section-subtitle">Get In Touch</h3>
      <h4 className="section-title">Contact Me</h4>
            <p>If you have any questions or if you'd like to collaborate with me on a project, feel free to send me an email and I'll get back to you as soon as possible.
            </p>  
            <a href="mailto:khensimalaeka@gmail.com"><button>Say Hello</button></a> 
    </div>
  )
}

export default Contact