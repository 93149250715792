import React from 'react';
import { FaGithub, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import './Socials.scss';

const Socials = () => {
  return (
    <div className="socials-sidebar">
        <ul>
            <li><a href="https://github.com/isthatkhensi?tab=repositories" target="_blank" rel="noopener noreferrer"><FaGithub/></a></li>
            <li><a href="https://www.instagram.com/khensimalaeka/" target="_blank" rel="noopener noreferrer"><FaInstagram/></a></li>
            <li><a href="https://www.linkedin.cn/in/khensani-silindane-17b535187/" target="_blank" rel="noopener noreferrer"><FaLinkedinIn/></a></li>
        </ul>
    </div>
  )
}

export default Socials