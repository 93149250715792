/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { motion } from 'framer-motion';
import { images } from '../../constants';
import {RiHtml5Fill, RiAngularjsFill} from 'react-icons/ri';
import {FaSass, FaReact} from 'react-icons/fa';
import {DiJavascript1,DiPython, DiWordpress } from 'react-icons/di';
import {SiPhp } from 'react-icons/si';
import './About.scss';

const About = () => {
  return (
    <div>
      
      <motion.div name="about" id="about" className='section about' >
        <div className="about-image">
          <img src={images.illustration} alt="Illustration of web author" />
        </div>
        <div className="about-content">
          <h3 className="section-subtitle">About Me</h3>
          <h4 className="section-title">I make cool things for the web.</h4>
          <p className="about-desc">
            Hey, Khensi here. I’m a frontend web developer. I specialise in building captivating websites that provide the best performant experience for your brand using the best resources available. Here are a few of the technologies I’m well versed in:
          </p>
          <div className="skill-badges">
            <div className="skill">
              <RiHtml5Fill></RiHtml5Fill>
            </div>
            <div className="skill">
              <FaSass></FaSass>
            </div>
            <div className="skill">
              <DiJavascript1></DiJavascript1>
            </div>
            <div className="skill">
              <DiPython></DiPython>
            </div>
            <div className="skill">
              <SiPhp></SiPhp>
            </div>
            <div className="skill">
              <RiAngularjsFill></RiAngularjsFill>
            </div>
            <div className="skill">
              <FaReact></FaReact>
            </div>
            <div className="skill">
              <DiWordpress></DiWordpress>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  )
}


export default About