import React from 'react';
import { images } from '../../constants';
import './Footer.scss';

const Footer = () => {
  return (
    <div className='footer'>
      <footer>
            <p>Designed &amp; Developed by Khensi Malaeka <br/> © 2022  All rights reserved</p>
            <img src={images.logo} alt="portfolio logo"/>
        </footer>
    </div>
  )
}

export default Footer