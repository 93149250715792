import { Navbar, Footer, Socials } from './components'
import {Header, About, Work, Contact} from './pages';
import './scss/index.scss';

function App() {
  return (
    <div className="App">
      <Navbar/>
      <Header/>
      <About/>
      <Work/>
      <Contact/>
      <Socials/>
      <Footer/>
    </div>
    
  );
}

export default App;
